"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUtilInstance = void 0;
var extToMimeTypeMapping = {
    svg: ['image/svg+xml'],
    svgz: ['image/svg+xml'],
    jpg: ['image/jpeg'],
    jpeg: ['image/jpeg'],
    png: ['image/png'],
    gif: ['image/gif'],
    bmp: ['image/bmp'],
    tif: ['image/tif'],
    tiff: ['image/tiff'],
    gltf: ['gltf+json', 'model/gltf-binary'],
    glb: ['application/octet-stream', 'model/gltf-binary'],
    bin: ['application/octet-stream', 'application/gltf-buffer'],
    '3dm': ['model/vnd.3dm', 'application/3dm', 'x-world/x-3dmf'],
    '3ds': ['application/x-3ds', 'image/x-3ds', 'application/3ds'],
    fbx: ['application/fbx'],
    dxf: ['application/dxf', 'application/x-autocad', 'application/x-dxf', 'drawing/x-dxf', 'image/vnd.dxf', 'image/x-autocad', 'image/x-dxf', 'zz-application/zz-winassoc-dxf'],
    dwg: ['application/dwg'],
    pdf: ['application/pdf'],
    '3mf': ['model/3mf'],
    stl: ['model/stl', 'application/sla'],
    amf: ['application/amf'],
    ai: ['application/ai'],
    dgn: ['application/dgn'],
    ply: ['application/ply'],
    ps: ['application/postscript'],
    eps: ['application/postscript'],
    skp: ['application/skp'],
    slc: ['application/slc'],
    sldprt: ['application/sldprt'],
    sldasm: ['application/sldasm'],
    stp: ['application/step'],
    step: ['application/step'],
    vda: ['application/vda'],
    gdf: ['application/gdf'],
    vrml: ['model/vrml', 'model/x3d-vrml'],
    wrl: ['model/vrml', 'model/x3d-vrml'],
    vi: ['model/vrml', 'model/x3d-vrml'],
    igs: ['model/iges', 'application/iges'],
    iges: ['model/iges', 'application/iges'],
    obj: ['model/obj', 'application/wavefront-obj'],
    off: ['application/off'],
    txt: ['text/plain'],
    mtl: ['text/plain'],
    g: ['text/plain'],
    gcode: ['text/plain'],
    glsl: ['text/plain'],
    csv: ['text/csv', 'application/vnd.ms-excel'],
    xls: ['application/vnd.ms-excel'],
    xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    xml: ['application/xml'],
    json: ['application/json'],
    ifc: ['application/x-step'],
    ifcxml: ['application/xml'],
    ifczip: ['application/zip'],
    sdtf: ['model/vnd.sdtf'],
    sddtf: ['model/vnd.sdtf'],
};
var FileUtil = /** @class */ (function () {
    function FileUtil() {
    }
    // #region Public Methods (1)
    /**
     * Try to guess a mime type from a file name
     * @param {String} filename
     * @return {String} guessed mime type, empty string in case none could be guessed
     */
    FileUtil.prototype.guessMimeTypeFromFilename = function (filename) {
        var parts = filename.split('.');
        if (!(parts.length > 0))
            return [];
        var extension = parts[parts.length - 1];
        var supportedExtensions = Object.keys(extToMimeTypeMapping);
        if (!supportedExtensions.includes(extension))
            return [];
        return extToMimeTypeMapping[extension];
    };
    /**
     * Returns the corresponding file ending for each mime type.
     * @param {string[]} mimeTypes
     * @return {string[]}
     */
    FileUtil.prototype.mapMimeTypeToFileEndings = function (mimeTypes) {
        var fileEndings = [];
        var _loop_1 = function (i) {
            var fileEnding = Object.keys(extToMimeTypeMapping).find(function (key) { return extToMimeTypeMapping[key].includes(mimeTypes[i]); });
            if (fileEnding)
                fileEndings.push('.' + fileEnding);
        };
        for (var i = 0; i < mimeTypes.length; i++) {
            _loop_1(i);
        }
        return fileEndings;
    };
    return FileUtil;
}());
exports.FileUtilInstance = new FileUtil();
