"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraControls = void 0;
var CameraEventManager_1 = require("./CameraEventManager");
var CameraInterpolationManager_1 = require("./CameraInterpolationManager");
var THREE = require("../../../../../externals/three");
var CameraControls = /** @class */ (function () {
    // #endregion Properties (12)
    // #region Constructors (1)
    function CameraControls(_camera, defaults, _domElement, _onChange, _eventDispatch, _adjustOrthographicSides) {
        this._camera = _camera;
        this._domElement = _domElement;
        this._onChange = _onChange;
        this._eventDispatch = _eventDispatch;
        this._adjustOrthographicSides = _adjustOrthographicSides;
        this._cameraInterpolationManager = new CameraInterpolationManager_1.CameraInterpolationManager(this);
        this._manualInteraction = false;
        this._moving = false;
        this._movingDuration = 0;
        this._nonmanualInteraction = false;
        this._position = new THREE.Vector3();
        this._target = new THREE.Vector3();
        this._position = defaults.position.clone();
        this._target = defaults.target.clone();
        this._camera.position.set(this._position.x, this._position.y, this._position.z);
        this._camera.lookAt(this._target);
        this._cameraEventManager = new CameraEventManager_1.CameraEventManager(this);
        this._manualInteractionMatrices = { position: [], target: [] };
        this._nonmanualInteractionMatrices = { position: [], target: [] };
    }
    Object.defineProperty(CameraControls.prototype, "camera", {
        // #endregion Constructors (1)
        // #region Public Accessors (6)
        get: function () {
            return this._camera;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CameraControls.prototype, "domElement", {
        get: function () {
            return this._domElement;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CameraControls.prototype, "enabled", {
        get: function () {
            return this._enabled;
        },
        set: function (enabled) {
            if (!enabled) {
                this._manualInteraction = false;
                this._manualInteractionMatrices = { position: [], target: [] };
                this._nonmanualInteraction = false;
                this._nonmanualInteractionMatrices = { position: [], target: [] };
                if (this._cameraInterpolationManager.active())
                    this._cameraInterpolationManager.stop();
                if (this._cameraControlsManager)
                    this._cameraControlsManager.reset();
            }
            this._enabled = enabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CameraControls.prototype, "position", {
        get: function () {
            return this.getPosition().clone();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CameraControls.prototype, "target", {
        get: function () {
            return this.getTarget().clone();
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (6)
    // #region Public Methods (12)
    CameraControls.prototype.animate = function (path, options) {
        if (options && options.duration === 0)
            return this.immediateUpdate(path[path.length - 1].position, path[path.length - 1].target);
        this._manualInteraction = false;
        this._manualInteractionMatrices = { position: [], target: [] };
        this._onChange();
        return this._cameraInterpolationManager.interpolate(path, options);
    };
    CameraControls.prototype.applyPositionMatrix = function (matrix, manualInteraction) {
        if (manualInteraction === void 0) { manualInteraction = false; }
        if (this._manualInteraction || manualInteraction) {
            this._manualInteraction = true;
            this._manualInteractionMatrices.position.push(matrix);
        }
        else {
            this._nonmanualInteraction = true;
            this._nonmanualInteractionMatrices.position.push(matrix);
        }
        this._onChange();
    };
    CameraControls.prototype.applyTargetMatrix = function (matrix, manualInteraction) {
        if (manualInteraction === void 0) { manualInteraction = false; }
        if (this._manualInteraction || manualInteraction) {
            this._manualInteraction = true;
            this._manualInteractionMatrices.target.push(matrix);
        }
        else {
            this._nonmanualInteraction = true;
            this._nonmanualInteractionMatrices.target.push(matrix);
        }
        this._onChange();
    };
    CameraControls.prototype.applyUpMatrix = function (matrix, manualInteraction) {
        if (manualInteraction === void 0) { manualInteraction = false; }
        throw new Error("Method not implemented.");
    };
    CameraControls.prototype.dispose = function () {
        this._cameraEventManager.dispose();
    };
    CameraControls.prototype.getPositionWithManualUpdates = function () {
        var position = new THREE.Vector3().set(this._position.x, this._position.y, this._position.z);
        if (this._manualInteraction) {
            for (var i = this._manualInteractionMatrices.position.length - 1; i >= 0; i--) {
                position.applyMatrix4(this._manualInteractionMatrices.position[i]);
            }
        }
        return position;
    };
    CameraControls.prototype.getTargetWithManualUpdates = function () {
        var target = new THREE.Vector3().set(this._target.x, this._target.y, this._target.z);
        if (this._manualInteraction) {
            for (var i = this._manualInteractionMatrices.target.length - 1; i >= 0; i--)
                target.applyMatrix4(this._manualInteractionMatrices.target[i]);
        }
        return target;
    };
    CameraControls.prototype.isMoving = function () {
        return this._manualInteraction || this._nonmanualInteraction;
    };
    CameraControls.prototype.isWithinRestrictions = function (position, target) {
        return this._cameraControlsManager.isWithinRestrictions(position, target);
    };
    CameraControls.prototype.registerCameraControls = function (cameraControlsManager) {
        this._cameraControlsManager = cameraControlsManager;
        this._cameraEventManager.cameraControlsManager = cameraControlsManager;
    };
    CameraControls.prototype.reset = function () {
        if (this._cameraInterpolationManager.active())
            this._cameraInterpolationManager.stop();
        if (this._cameraControlsManager)
            this._cameraControlsManager.reset();
    };
    CameraControls.prototype.update = function (time) {
        if (!this._enabled)
            return;
        if (time === 0)
            return;
        var _a = this._cameraControlsManager.restrict(this.getPosition(), this.getTarget()), position = _a.position, target = _a.target;
        this._position.copy(position);
        this._target.copy(target);
        this._camera.position.set(this._position.x, this._position.y, this._position.z);
        this._camera.lookAt(this._target);
        // reset all values
        if (this._manualInteraction === true && this._cameraInterpolationManager.active())
            this._cameraInterpolationManager.stop();
        this._manualInteraction = false;
        this._manualInteractionMatrices = { position: [], target: [] };
        this._nonmanualInteraction = this._cameraInterpolationManager.active();
        this._nonmanualInteractionMatrices = { position: [], target: [] };
        if (this.camera.isOrthographicCamera)
            this._adjustOrthographicSides();
        this._cameraControlsManager.update(time, this._nonmanualInteraction);
        this._eventDispatch(this._manualInteraction || this._nonmanualInteraction, this._moving, this._movingDuration += time);
        this._moving = (this._manualInteraction || this._nonmanualInteraction);
        if (!this._moving)
            this._movingDuration = 0;
        if (this._manualInteraction || this._nonmanualInteraction)
            this._onChange();
    };
    // #endregion Public Methods (12)
    // #region Private Methods (3)
    CameraControls.prototype.getPosition = function () {
        var position = new THREE.Vector3().set(this._position.x, this._position.y, this._position.z);
        if (this._manualInteraction) {
            for (var i = this._manualInteractionMatrices.position.length - 1; i >= 0; i--) {
                position.applyMatrix4(this._manualInteractionMatrices.position[i]);
            }
        }
        else if (this._nonmanualInteraction) {
            for (var i = this._nonmanualInteractionMatrices.position.length - 1; i >= 0; i--)
                position.applyMatrix4(this._nonmanualInteractionMatrices.position[i]);
        }
        return position;
    };
    CameraControls.prototype.getTarget = function () {
        var target = new THREE.Vector3().set(this._target.x, this._target.y, this._target.z);
        if (this._manualInteraction) {
            for (var i = this._manualInteractionMatrices.target.length - 1; i >= 0; i--)
                target.applyMatrix4(this._manualInteractionMatrices.target[i]);
        }
        else if (this._nonmanualInteraction) {
            for (var i = this._nonmanualInteractionMatrices.target.length - 1; i >= 0; i--)
                target.applyMatrix4(this._nonmanualInteractionMatrices.target[i]);
        }
        return target;
    };
    CameraControls.prototype.immediateUpdate = function (position, target) {
        var obj = this._cameraControlsManager.restrict(position, target);
        this._position.copy(obj.position);
        this._target.copy(obj.target);
        this._camera.position.set(this._position.x, this._position.y, this._position.z);
        this._camera.lookAt(this._target);
        this._manualInteraction = false;
        this._manualInteractionMatrices = { position: [], target: [] };
        this._nonmanualInteraction = false;
        this._nonmanualInteractionMatrices = { position: [], target: [] };
        if (this._cameraInterpolationManager.active())
            this._cameraInterpolationManager.stop();
        if (this.camera.isOrthographicCamera)
            this._adjustOrthographicSides();
        this._onChange();
        return Promise.resolve(true);
    };
    return CameraControls;
}());
exports.CameraControls = CameraControls;
