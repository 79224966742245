/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiImplementationV2.1.js*
 *
 * ### Content
 *   * Implementation of the ShapeDiver 3D Viewer API V2.1
 *
 * @module ApiImplementationV2
 * @author ShapeDiver <contact@shapediver.com>
 */

/**
  * Imported messaging constant definitions
  */
var messagingConstants = require('../../shared/constants/MessagingConstants');

/**
  * Import GlobalUtils
  */
var GlobalUtils = require('../../shared/util/GlobalUtils');

/**
 * ApiInterfaceV2
 */
var ApiInterfaceV2 = new (require('./ApiInterfaceV2.1'))();

/**
 * APIResponse factory
 */
const APIResponse = require('./ApiResponse');

var Settings = require('shapedivernodemodule-viewersettings').Settings_2_0;

const ExportApi = require('./implementation/ApiExportImplementationV2.1');
const ParameterApi = require('./implementation/ApiParameterImplementationV2.1');
const PluginApi = require('./implementation/ApiPluginImplementationV2.1');
const SceneApi = require('./implementation/ApiSceneImplementationV2.1');
const StateApi = require('./implementation/ApiStateImplementationV2.1');
const ViewportsApi = require('./implementation/ApiViewportsImplementationV2.1');



/**
* ShapeDiver 3D Viewer API V2
*
* @class
* @implements {module:ApiInterfaceV2~ApiInterfaceV2}
* @param {Object} references - Object containing references to various handlers
* @param {Object} references.app - Reference to the complete app (will likely be removed at some point)
* @param {Object} references.exportHandler - Reference to the export handler
* @param {Object} references.loggingHandler - Reference to the logging handler
* @param {Object} references.messagingHandler - Reference to the messaging handler
* @param {Object} references.parameterHandler - Reference to the parameter handler
* @param {Object} references.pluginHandler - Reference to the plugin handler
* @param {Object} references.processStatusHandler - Reference to the process status handler
* @param {Object} references.sceneManager - Reference to the scene manager
* @param {Object} references.settingsHandler - Reference to the settings handler for persistent storage of settings
* @param {Object} references.viewportManager - Reference to the viewport manager
* @param {Object} references.interactionGroupManager - Reference to the interaction group manager
* @param {Object} settings - API settings
* @param {String} [settings.runtimeId] - Optional runtime id to use for the API instance
*/
var ApiImplementationV2 = function (___refs, ___settings) {

  var that = this;

  // deep copy the settings
  let _settings = GlobalUtils.deepCopy(___settings);

  // create a runtime id if none was given
  if (!_settings.runtimeId || !GlobalUtils.typeCheck(_settings.runtimeId, 'string'))
    _settings.runtimeId = GlobalUtils.createRandomId();

  // include enums from interface definition
  this.EVENTTYPE = ApiInterfaceV2.EVENTTYPE;

  /** @inheritdoc */
  this.getRuntimeId = function () {
    return _settings.runtimeId;
  };

  // shortcuts for accessing ViewerApp functionality
  var _app = ___refs.app;
  var _exportHandler = ___refs.exportHandler;
  var _messagingHandler = ___refs.messagingHandler;
  var _parameterHandler = ___refs.parameterHandler;
  var _pluginHandler = ___refs.pluginHandler;
  var _processStatusHandler = ___refs.processStatusHandler;
  var _sceneManager = ___refs.sceneManager;
  var _settingsHandler = ___refs.settingsHandler;
  var _viewportManager = ___refs.viewportManager;
  var _sceneGeometryManager = ___refs.sceneGeometryManager;
  var _interactionGroupManager = ___refs.interactionGroupManager;
  var _arApi = ___refs.arApi;

  // regex for match scene settings
  const SCENE_SETTINGS_REGEX = /^scene\./;

  // inject logging functionality
  GlobalUtils.inject(___refs.loggingHandler, that);

  // container for process subscription tokens
  var _processSubscriptions = {};

  /**
   * Clear a callback function which was previously registered using {@link module:ApiInterfaceV2~ApiInterfaceV2#setProcessCallback setProcessCallback}.
   *
   * Your subscription to the process messages for a given process token ends automatically once the process ultimately
   * succeeded or failed. You may unsubscribe before that using this function.
   * @param {String} token - The token which was returned from {@link module:ApiInterfaceV2~ApiInterfaceV2#setProcessCallback setProcessCallback}.
   * @return {Boolean} true if callback was unregistered successfully, false otherwise.
   */
  this.clearProcessCallback = function (token) {
    // allow one subscription per token only (for now, easier to implement)
    if (!_processSubscriptions.hasOwnProperty(token)) {
      return false;
    }
    _messagingHandler.unsubscribeFromMessageStream(_processSubscriptions[token].subToken);
    delete _processSubscriptions[token];
    return true;
  };

  /**
   * Register a callback function which will be invoked for every message related to the given process token.
   *
   * Your callback will be invoked with the following two parameters:
   *   * message topic, e.g. process.TOKEN
   *   * message object
   *
   * Your subscription to the process messages ends automatically once the process ultimately succeeded or failed. You may
   * unsubscribe before that using {@link module:ApiInterfaceV2~ApiInterfaceV2#clearProcessCallback clearProcessCallback}.
   * @param {String} token - The token which identifies the process
   * @param {Function} callback - The callback to invoke for every message related to the given process token
   * @return {Object} subscription token which can be used for unsubscribing again, undefined in case of error
   */
  this.setProcessCallback = function (token, cb) {
    var scope = 'ApiImplementationV2.setProcessCallback';

    // allow one subscription per token only (for now, easier to implement)
    if (_processSubscriptions.hasOwnProperty(token)) {
      return;
    }

    // compose topic for subscription, subscribe
    let t = messagingConstants.messageTopics.PROCESS + '.' + token;
    let subToken = _messagingHandler.subscribeToMessageStream(t, (topic, msg) => {

      // get process token from topic (string after first dot)
      let topicToken = topic.substring(topic.indexOf('.') + 1);

      // get handle to process subscription object
      if (!_processSubscriptions.hasOwnProperty(token)) {
        that.warn(scope, 'Message for unknown token:', msg);
        return;
      }
      let s = _processSubscriptions[token];

      // handle msgs which have a part messagingConstants.messageDataTypes.PROCESS_FORK
      // in that case replace array of process tokens
      let pf = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_FORK);
      if (pf && Array.isArray(pf.data)) {
        // check new process tokens which shall replace topicToken,
        // every one of them must be a sub-token of the original one (i.e. define a sub-topic, otherwise we won't receive its messages)
        let r = pf.data.every((n) => {
          return n.startsWith(token + '.');
        });
        if (!r) {
          that.error(scope, 'Forked process token must be a sub-token of ' + token);
        }
        else {
          // remove old process token
          let newProcTokens = s.procTokens;
          newProcTokens = newProcTokens.splice(newProcTokens.indexOf(topicToken), 1);
          // add new process tokens and remember them
          newProcTokens = newProcTokens.concat(pf.data);
          s.procTokens = newProcTokens;
        }
        try {
          cb(topic, msg);
        } catch (e) {
          that.error(scope, 'Exception in process callback:', e);
        }
        return;
      }

      // handle msgs which have a part messagingConstants.messageDataTypes.PROCESS_ERROR or PROCESS_SUCCESS
      // in that case remember status for the corresponding process token
      let pe = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_ERROR);
      let pa = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_ABORT);
      let ps = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_SUCCESS);
      if (pe) {
        s.procTokenStat[topicToken] = messagingConstants.messageDataTypes.PROCESS_ERROR;
      }
      else if (pa) {
        s.procTokenStat[topicToken] = messagingConstants.messageDataTypes.PROCESS_ABORT;
      }
      else if (ps) {
        s.procTokenStat[topicToken] = messagingConstants.messageDataTypes.PROCESS_SUCCESS;
      }

      // once we have collected a status for all process tokens, cancel the subscription
      let r = s.procTokens.every((t) => {
        return s.procTokenStat.hasOwnProperty(t);
      });
      if (r) {
        that.debug(scope, 'Clearing process callback for token ' + token);
        that.clearProcessCallback(token);
      }

      try {
        cb(topic, msg);
      } catch (e) {
        that.error(scope, 'Exception in process callback:', e);
      }
    });

    // store subscription token and process token
    _processSubscriptions[token] = {
      subToken: subToken, // subscription token
      procTokens: [token], // list of process tokens (process might be forked)
      procTokenStat: {} // status for each process token
    };
    return token;
  };

  /**
    * Helper functionality for pubsub subscriptions.
    * Catches exceptions and logs them as warning.
    *
    * @param {String} topic
    * @param {Function} callback
    * @return {Object} subscription token
    */
  this.subscribeToMessageStream = function (t, cb) {
    let scope = 'ApiImplementationV2.subscribeToMessageStream';
    return _messagingHandler.subscribeToMessageStream(t, (topic, msg) => {
      try {
        // add / change properties of msg
        msg = msg || {};
        msg.api = that;
        cb(topic, msg);
      } catch (e) {
        that.warn(scope, 'Exception in callback for topic ' + topic, e, msg);
      }
    });
  };

  /**
    * Helper functionality for pubsub unsubscription.
    *
    * @param {Object|Object[]} tokens
    */
  this.unsubscribeFromMessageStream = function (tokens) {
    return _messagingHandler.unsubscribeFromMessageStream(tokens);
  };

  /** @inheritdoc */
  this.state = new StateApi(that, {
    processStatusHandler: _processStatusHandler,
    messagingHandler: _messagingHandler
  });

  /** @inheritdoc */
  this.parameters = new ParameterApi(that, {
    parameterHandler: _parameterHandler,
    messagingHandler: _messagingHandler
  });

  /** @inheritdoc */
  this.plugins = new PluginApi(that, {
    pluginHandler: _pluginHandler,
    messagingHandler: _messagingHandler,
    parameterHandler: _parameterHandler
  });

  /** @inheritdoc */
  this.exports = new ExportApi(that, {
    exportHandler: _exportHandler,
    parameterHandler: _parameterHandler
  });

  /** @inheritdoc */
  this.viewports = new ViewportsApi(that, {
    viewportManager: _viewportManager,
  });

  /** @inheritdoc */
  this.scene = new SceneApi(that, {
    sceneManager: _sceneManager,
    viewportManager: _viewportManager,
    sceneGeometryManager: _sceneGeometryManager,
    interactionGroupManager: _interactionGroupManager,
    messagingHandler: _messagingHandler
  });

  let allDefinitions = new Settings().getSettingDefinitions();
  let appDefinitions = {};
  for(let s in allDefinitions) {
    if(s.startsWith('viewer.') && !s.startsWith('viewer.scene.')) {
      appDefinitions[s.replace('viewer.', '')] = allDefinitions[s];
    } else if(s.startsWith('ar.') || s.startsWith('defaultMaterial.')) {
      appDefinitions[s] = allDefinitions[s];
    }
  }

  let appSettings = new Settings();
  delete appSettings.settings.viewer.scene;
  delete appSettings.settings.parameters;


  /** @inheritdoc */
  this.addEventListener = function (type, cb) {
    // check if event type is supported
    if (!Object.keys(that.EVENTTYPE).find((k) => (that.EVENTTYPE[k] === type)))
      return APIResponse('Unsupported event type');
    // compose topic and subscribe to message stream
    let t = type;
    let subtokens = that.subscribeToMessageStream(t, function (topic, msg) {
      // create event object, add common event properties
      let event = new CustomEvent(type);
      event.api = msg.api;
      if (msg.token) event.token = msg.token;
      
      // get relevant data parts from message, add special event properties
      let part = msg.getUniquePartByType(messagingConstants.messageDataTypes.SETTINGS_UPDATE);
      if (!part) {
        cb(event);
        return;
      }
      if (part.data) part = part.data;

      // transform settings 'key' using 'namespace' and _settingsDef, unless it is a viewport setting
      event.settings = {};
      let settings = event.settings;
      let viewportRuntimeIds = _viewportManager.api.getViewportRuntimeId();
      if(viewportRuntimeIds.includes(part.namespace)) {
        settings.key = part.key;
        settings.runtimeId = part.namespace;
        settings.namespace = 'scene';
      } else {
        if(part.namespace === 'app') {
          let setting = appDefinitions[part.key];
          if(setting !== undefined) {
            settings.key = part.key;
            settings.runtimeId = that.getRuntimeId();
          }
        }
      }

      if (!settings.key) return;
      settings.valueNew = part.valueNew;
      settings.valueOld = part.valueOld;
      // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
      cb(event);
    });
    return APIResponse(null, subtokens);
  };

  /** @inheritdoc */
  this.removeEventListener = function (token) {
    return APIResponse(null, that.unsubscribeFromMessageStream(token));
  };

  /** @inheritdoc */
  this.getSettingDefinitions = function () {
    let allDefinitions = new Settings().getSettingDefinitions();
    let sceneDefinitions = {}
    for(let s in allDefinitions) {
      if(s.startsWith('viewer.')) {
        sceneDefinitions[s.replace('viewer.', '')] = allDefinitions[s];
      } else if(s.startsWith('parameters.')) {
        sceneDefinitions[s.replace('parameters.', '')] = allDefinitions[s];
      } else {
        sceneDefinitions[s] = allDefinitions[s];
      }
    }
    return sceneDefinitions;
  };

  /** @inheritdoc */
  this.getSettings = function (keys) {
    if (!Array.isArray(keys)) {
      keys = Object.keys(that.getSettingDefinitions());
      let temp_keys = Object.keys(_viewportManager.api.getSettingDefinitions());
      for (let j = 0, len = temp_keys.length; j < len; j++) {
        let skey = 'scene.' + temp_keys[j];
        if (!keys.includes(skey)) {
          keys.push(skey);
        }
      }
    }

    let settings = {};
    keys.forEach((k) => {
      let tempKey = k;
      if(!k.startsWith('ar') && !k.startsWith('defaultMaterial')) tempKey = 'viewer.' + tempKey;
      let sk = appSettings.getSettingObject(tempKey);
      if (sk !== undefined) {
         let v = _app.getSetting(k);
         GlobalUtils.forceAtPath(settings, k, v);
       } else {
        let v = _viewportManager.api.getSetting(k.replace(SCENE_SETTINGS_REGEX, ''));
        if (v !== undefined) GlobalUtils.forceAtPath(settings, k, v);
      }
    });

    return settings;
  };

  /** @inheritdoc */
  this.getSetting = function (k) {
    let tempKey = k;
    if(!k.startsWith('ar') && !k.startsWith('defaultMaterial')) tempKey = 'viewer.' + tempKey;
    let sk = appSettings.getSettingObject(tempKey);
    if (sk === undefined)
      return _viewportManager.api.getSetting(k.replace(SCENE_SETTINGS_REGEX, ''));

    return _app.getSetting(k);
  };

  /** @inheritdoc */
  this.updateSettingAsync = function (k, val) {
    if(k === 'legacyGLTFLoader') return _app.updateSettingAsync('legacyGLTFLoader', val);
    // Exception for ViewportVisibilityHandler as there is a hook for a setting that belongs at a completly different place #SS-1519
    if(k === 'scene.show') return _app.updateSettingAsync('show', val);

    let tempKey = k;
    if(!k.startsWith('ar') && !k.startsWith('defaultMaterial')) tempKey = 'viewer.' + tempKey;
    let m = appSettings.getSettingObject(tempKey);
    if (m === undefined)
      return _viewportManager.api.updateSettingAsync(k.replace(SCENE_SETTINGS_REGEX, ''), val);

    // type checking
    if (m.type !== undefined && m.type !== null) {
      if (GlobalUtils.typeCheck(m.type, 'string')) {
        if (!GlobalUtils.typeCheck(val, m.type)) {
          return Promise.resolve(APIResponse('Setting has wrong value type', false));
        }
      }
      else if (typeof m.type === 'function') {
        if (!m.type(val)) {
          return Promise.resolve(APIResponse('Setting has wrong value type', false));
        }
      }
    }

    // update setting
    return _app.updateSettingAsync(k, val).then(
      (r) => {
        if (!r) {
          return Promise.resolve(APIResponse('Update of setting failed', false));
        }
        else {
          return Promise.resolve(APIResponse(null, true));
        }
      },
      () => {
        return Promise.resolve(APIResponse('Update of setting failed', false));
      }
    );
  };

  /** @inheritdoc */
  this.updateSettingsAsync = function (settings) {
    // get paths of settings object
    let paths = [];
    GlobalUtils.getPaths(settings, paths);

    // filter out all vector definitions and use the parent
    for(let i = 0; i < paths.length; i++) {
      if(paths[i].endsWith('.x') || paths[i].endsWith('.y') || paths[i].endsWith('.z')) {
        // set current path to the name and filter out the other two
        paths[i] = paths[i].substring(0, paths[i].length-2);
        paths = paths.filter(elem => !(elem.startsWith(paths[i]) && paths[i].length + 2 === elem.length));
      }
    }

    // create an empty object which will hold the results
    let results = {};

    // create an empty promise to attach further ones to
    let promiseChain = Promise.resolve();
    for (let path of paths) {
      // attach promise for updating the setting at path
      promiseChain = promiseChain.then(function () {
        return that.updateSettingAsync(path, GlobalUtils.getAtPath(settings, path));
      });
      // attach promise for storing the result of the setting update
      promiseChain = promiseChain.then(
        function (r) {
          if (r.err) {
            GlobalUtils.forceAtPath(results, path, false);
          } else {
            GlobalUtils.forceAtPath(results, path, true);
          }
        },
        function () {
          GlobalUtils.forceAtPath(results, path, false);
        }
      );
    }

    // add final result to promise chain
    return promiseChain.then(
      function () {
        return APIResponse(null, results);
      }
    );
  };

  /** @inheritdoc */
  this.createAsynchronousFunctions = function () {
    let createAsync = function (obj) {
      for (let key in obj) {
        if (obj[key] instanceof Function) {
          if (!(key.endsWith('Async') || key.endsWith('EventListener'))) {
            if (!obj[key + 'Async']) {
              obj[key + 'Async'] = function (...variables) {
                return new Promise((resolve, reject) => {
                  resolve(obj[key].call(obj, ...variables));
                });
              };
            }
          }
        } else if (['state', 'parameters', 'plugins', 'exports', 'viewports', 'scene', 'camera', 'lights'].indexOf(key) > -1) {
          createAsync(obj[key]);
        }
      }
    };
    createAsync(this);
  };

  /** @inheritdoc */
  this.saveSettingsAsync = function (plugin) {
    return _settingsHandler.saveSettings(plugin)
      .then(
        function (result) {
          return APIResponse(null, result);
        },
        function (err) {
          return APIResponse(err, false);
        }
      );
  };

  /** @inheritdoc */
  if (_arApi) {
    this.ar = _arApi;
  }

  /**
  * attach GlobalUtils
  */
  this.utils = GlobalUtils;

};

// export the constructor
module.exports = ApiImplementationV2;
