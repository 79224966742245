"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeometryData = exports.PrimitiveData = exports.AttributeData = exports.PRIMITIVE_MODE = void 0;
var AbstractTreeNodeData_1 = require("./AbstractTreeNodeData");
var THREE = __importStar(require("../../../../../externals/three"));
var PRIMITIVE_MODE;
(function (PRIMITIVE_MODE) {
    PRIMITIVE_MODE[PRIMITIVE_MODE["POINTS"] = 0] = "POINTS";
    PRIMITIVE_MODE[PRIMITIVE_MODE["LINES"] = 1] = "LINES";
    PRIMITIVE_MODE[PRIMITIVE_MODE["LINE_LOOP"] = 2] = "LINE_LOOP";
    PRIMITIVE_MODE[PRIMITIVE_MODE["LINE_STRIP"] = 3] = "LINE_STRIP";
    PRIMITIVE_MODE[PRIMITIVE_MODE["TRIANGLES"] = 4] = "TRIANGLES";
    PRIMITIVE_MODE[PRIMITIVE_MODE["TRIANGLE_STRIP"] = 5] = "TRIANGLE_STRIP";
    PRIMITIVE_MODE[PRIMITIVE_MODE["TRIANGLE_FAN"] = 6] = "TRIANGLE_FAN";
})(PRIMITIVE_MODE = exports.PRIMITIVE_MODE || (exports.PRIMITIVE_MODE = {}));
var AttributeData = /** @class */ (function () {
    // #endregion Properties (13)
    // #region Constructors (1)
    /**
     * Creates an attribute data object.
     *
     * @param _array the array of the data
     * @param _itemSize the size
     * @param _hasOffset notifier if there is an offset
     * @param _offset the offset
     * @param _stride the stride
     * @param _normalized boolean if the data is normalized
     */
    function AttributeData(array, itemSize, itemBytes, byteOffset, elementBytes, normalized, count, min, max, byteStride, sparse, sparseIndices, sparseValues) {
        if (min === void 0) { min = []; }
        if (max === void 0) { max = []; }
        this._max = [];
        this._min = [];
        this._array = array;
        this._itemSize = itemSize;
        this._itemBytes = itemBytes;
        this._byteOffset = byteOffset;
        this._elementBytes = elementBytes;
        this._normalized = normalized;
        this._count = count;
        this._min = min;
        this._max = max;
        this._byteStride = byteStride;
        this._sparse = sparse;
        this._sparseIndices = sparseIndices;
        this._sparseValues = sparseValues;
    }
    Object.defineProperty(AttributeData.prototype, "array", {
        // #endregion Constructors (1)
        // #region Public Accessors (13)
        get: function () {
            return this._array;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "byteOffset", {
        get: function () {
            return this._byteOffset;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "byteStride", {
        get: function () {
            return this._byteStride;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "count", {
        get: function () {
            return this._count;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "elementBytes", {
        get: function () {
            return this._elementBytes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "itemBytes", {
        get: function () {
            return this._itemBytes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "itemSize", {
        get: function () {
            return this._itemSize;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "max", {
        get: function () {
            return this._max;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "min", {
        get: function () {
            return this._min;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "normalized", {
        get: function () {
            return this._normalized;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "sparse", {
        get: function () {
            return this._sparse;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "sparseIndices", {
        get: function () {
            return this._sparseIndices;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttributeData.prototype, "sparseValues", {
        get: function () {
            return this._sparseValues;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (13)
    // #region Public Methods (1)
    /**
     * Clones the attribute data.
     */
    AttributeData.prototype.clone = function () {
        var array = this._array.slice(0, this._array.length);
        array.set(this._array);
        return new AttributeData(array, this._itemSize, this._itemBytes, this._byteOffset, this._elementBytes, this._normalized, this._count, this._min, this._max, this._byteStride, this._sparse, this._sparseIndices, this._sparseValues);
    };
    return AttributeData;
}());
exports.AttributeData = AttributeData;
var PrimitiveData = /** @class */ (function () {
    // #endregion Properties (5)
    // #region Constructors (1)
    /**
     * Creates a primitive data object.
     *
     * @param _attributes the attributes as key-value pairs
     * @param _indices the indices
     */
    function PrimitiveData(attributes, mode, indices, material, attributeMaterial) {
        if (attributes === void 0) { attributes = {}; }
        if (mode === void 0) { mode = PRIMITIVE_MODE.TRIANGLES; }
        if (indices === void 0) { indices = null; }
        if (material === void 0) { material = null; }
        if (attributeMaterial === void 0) { attributeMaterial = null; }
        // #region Properties (5)
        this._attributes = {};
        this._mode = PRIMITIVE_MODE.TRIANGLES;
        this._indices = null;
        this._material = null;
        this._effectMaterials = [];
        this._attributeMaterial = null;
        this._attributes = attributes;
        this._mode = mode;
        this._indices = indices;
        this._material = material;
        this._attributeMaterial = attributeMaterial;
    }
    Object.defineProperty(PrimitiveData.prototype, "attributes", {
        // #endregion Constructors (1)
        // #region Public Accessors (7)
        get: function () {
            return this._attributes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PrimitiveData.prototype, "indices", {
        get: function () {
            return this._indices;
        },
        set: function (value) {
            this._indices = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PrimitiveData.prototype, "material", {
        get: function () {
            return this._material;
        },
        set: function (value) {
            this._material = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PrimitiveData.prototype, "effectMaterials", {
        get: function () {
            return this._effectMaterials;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PrimitiveData.prototype, "attributeMaterial", {
        get: function () {
            return this._attributeMaterial;
        },
        set: function (value) {
            this._attributeMaterial = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PrimitiveData.prototype, "mode", {
        get: function () {
            return this._mode;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (7)
    // #region Public Methods (1)
    /**
     * Clones the primitive data.
     */
    PrimitiveData.prototype.clone = function () {
        var _a, _b, _c;
        var attributes = {};
        for (var attribute in this._attributes)
            attributes[attribute] = this._attributes[attribute].clone();
        return new PrimitiveData(attributes, this._mode, (_a = this._indices) === null || _a === void 0 ? void 0 : _a.clone(), (_b = this._material) === null || _b === void 0 ? void 0 : _b.clone(), (_c = this._attributeMaterial) === null || _c === void 0 ? void 0 : _c.clone());
    };
    return PrimitiveData;
}());
exports.PrimitiveData = PrimitiveData;
var GeometryData = /** @class */ (function (_super) {
    __extends(GeometryData, _super);
    // #endregion Properties (4)
    // #region Constructors (1)
    /**
     * Creates a geometry data object.
     *
     * @param _primitive the primitive
     * @param _matrix the matrix to apply
     * @param id the id
     */
    function GeometryData(primitive, matrix, id) {
        if (matrix === void 0) { matrix = new THREE.Matrix4(); }
        var _this = _super.call(this, id) || this;
        _this._primitive = primitive;
        _this._matrix = matrix;
        return _this;
    }
    Object.defineProperty(GeometryData.prototype, "matrix", {
        // #endregion Constructors (1)
        // #region Public Accessors (5)
        get: function () {
            return this._matrix;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GeometryData.prototype, "primitive", {
        get: function () {
            return this._primitive;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (5)
    // #region Public Methods (2)
    /**
     * Clones the scene graph data.
     */
    GeometryData.prototype.clone = function () {
        return new GeometryData(this._primitive.clone(), this.matrix.clone(), this.id);
    };
    return GeometryData;
}(AbstractTreeNodeData_1.AbstractTreeNodeData));
exports.GeometryData = GeometryData;
