"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACCESSORTYPE = exports.ACCESSORCOMPONENTSIZE = exports.ACCESSORCOMPONENTTYPE = void 0;
exports.ACCESSORCOMPONENTTYPE = {
    5120: Int8Array,
    5121: Uint8Array,
    5122: Int16Array,
    5123: Uint16Array,
    5124: Uint16Array,
    5125: Uint32Array,
    5126: Float32Array
};
exports.ACCESSORCOMPONENTSIZE = {
    5120: 1,
    5121: 1,
    5122: 2,
    5123: 2,
    5125: 4,
    5126: 4
};
exports.ACCESSORTYPE = {
    SCALAR: 1,
    VEC2: 2,
    VEC3: 3,
    VEC4: 4,
    MAT2: 4,
    MAT3: 9,
    MAT4: 16
};
