"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileCustomComponent = void 0;
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var FileUtil_1 = require("../../../../shared/util/FileUtil");
var FileCustomComponent = /** @class */ (function (_super) {
    __extends(FileCustomComponent, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function FileCustomComponent(id, parameterDefinition, parent, domManager, queue, _fileHelper, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, "file" /* FILE */, id, parameterDefinition, parent, domManager, queue, editMode) || this;
        _this._fileHelper = _fileHelper;
        _this._inputId = _this.id + "-button";
        _this.createElement();
        _this.createInputEvents();
        _this.updateLabel(_this.parameterDefinition.value);
        _this._fileName = _this.parameterDefinition.name;
        return _this;
    }
    Object.defineProperty(FileCustomComponent.prototype, "fileInput", {
        // #endregion Constructors (1)
        // #region Public Accessors (2)
        get: function () {
            return this._fileInput;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileCustomComponent.prototype, "size", {
        get: function () {
            return 1 /* QTR_WIDTH */;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (2)
    // #region Protected Methods (4)
    FileCustomComponent.prototype.createInput = function () {
        var _a;
        this._inputComponent = this._domManager.createDomElement("button", this._inputId, this._inputContainer, ['uk-button', 'uk-button-default', 'uk-button-small', 'uk-width-expand', 'text-transform-none', 'uk-padding-remove', 'sd-control-button'], { type: 'button', name: this._inputId });
        this._inputComponent.innerHTML = "<span>" + ((_a = this._parameterDefinition.name) !== null && _a !== void 0 ? _a : '+') + "</span>";
        var fileInputId = this._id + "-file";
        this._fileInput = this._domManager.createDomElement("input", fileInputId, this._inputContainer, [], { type: 'file', name: fileInputId ? fileInputId : '+' });
        this._fileInput.style.display = 'none';
        this._fileInput.multiple = true;
        if (this._parameterDefinition.allowedMimeTypes) {
            var accept = this._parameterDefinition.allowedMimeTypes;
            if (typeof accept === "string") {
                accept = [accept];
            }
            var fileEndings = FileUtil_1.FileUtilInstance.mapMimeTypeToFileEndings(accept);
            this._fileInput.accept = accept.join(',') + ',' + fileEndings.join(',');
        }
        else if (this._parameterDefinition.accept) {
            var accept = this._parameterDefinition.accept;
            if (typeof accept === "string") {
                accept = [accept];
            }
            var fileEndings = FileUtil_1.FileUtilInstance.mapMimeTypeToFileEndings(accept);
            this._fileInput.accept = accept.join(',') + ',' + fileEndings.join(',');
        }
        this._fileInput.accept = this._fileInput.accept.replace("text/csv", ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel");
    };
    FileCustomComponent.prototype.createInputEvents = function () {
        var _this = this;
        var _spacePressed = false;
        this._domManager.setDomEventListener(this._inputComponent, "keyup", null, function (val, event) {
            var keyEvent = event;
            if (keyEvent.code == "Space") {
                _spacePressed = true;
            }
        });
        this._domManager.setDomEventListener(this._inputComponent, "click", 10, function (val, event) {
            if (_spacePressed === false) {
                _this._fileInput.click();
            }
            _spacePressed = false;
        });
        this.bindChangeEvent();
        this.inputComponent.addEventListener('dragenter', this.dragHandler, false);
        this.inputComponent.addEventListener('dragover', this.dragHandler, false);
        this.inputComponent.addEventListener('drop', this.dropHandler, false);
    };
    FileCustomComponent.prototype.createLabel = function () {
        _super.prototype.createLabel.call(this);
    };
    FileCustomComponent.prototype.update = function (v, event) {
        for (var i = 0; i < this.fileInput.files.length; i++) {
            var file = this.fileInput.files.item(i);
            if (!this.checkFileValidityAndStartLoading(file))
                return;
        }
        try {
            var promise = this._parameterDefinition.update(this._fileInput.files);
            if (promise && promise instanceof Promise) {
                promise.then(this.updateLabel.bind(this));
            }
            else {
                this.updateLabel();
            }
        }
        catch (ex) {
            this.log(ex);
            this.updateLabel();
        }
    };
    // #endregion Protected Methods (4)
    // #region Private Methods (11)
    FileCustomComponent.prototype.bindChangeEvent = function () {
        this._domManager.setDomEventListener(this._fileInput, "change", 10, this.update.bind(this));
    };
    FileCustomComponent.prototype.checkFileValidityAndStartLoading = function (value) {
        if (value instanceof File) {
            var types_1 = [];
            // get the format, if there is none guess it from the name
            if (value.type.length > 0) {
                types_1 = [value.type];
                // get all endings that are possible for this type
                var endings = FileUtil_1.FileUtilInstance.mapMimeTypeToFileEndings(types_1);
                // get all mimeTypes that are possible for these endings
                endings.forEach(function (e) { return types_1 = types_1.concat(FileUtil_1.FileUtilInstance.guessMimeTypeFromFilename(e)); });
            }
            else {
                // get all mimeTypes that are possible for this file
                types_1 = FileUtil_1.FileUtilInstance.guessMimeTypeFromFilename(value.name);
            }
            // check if one of the mime types is allowed
            var allowedType = false;
            for (var i = 0; i < types_1.length; i++) {
                if (this.checkMimeType(types_1[i])) {
                    allowedType = true;
                    value._type = types_1[i];
                    break;
                }
            }
            // deny update if the format is not allowed
            if (!allowedType) {
                this.uploadError();
                if (Array.isArray(this._parameterDefinition.accept)) {
                    this.log("Allowed file types: " + this._parameterDefinition.accept.join(', '));
                }
                else {
                    this.log("Allowed file types: " + this._parameterDefinition.accept);
                }
                return false;
            }
            // deny update if the file size is exceeded
            if (!this.checkSize(value.size)) {
                this.uploadError();
                this.log("Maximum file size: " + this._parameterDefinition.max + " bytes");
                return false;
            }
            this.removeUploadError();
            this.label.innerHTML = '<div uk-spinner="ratio: 0.5"></div>';
            this._fileName = value.name;
            return true;
        }
        return false;
    };
    FileCustomComponent.prototype.checkMimeType = function (type) {
        if (!this._parameterDefinition.allowedMimeTypes) {
            return true;
        }
        if (Array.isArray(this._parameterDefinition.allowedMimeTypes)) {
            return this._parameterDefinition.allowedMimeTypes.some(function (x) { return x == type; });
        }
        return this._parameterDefinition.allowedMimeTypes.includes(type);
    };
    FileCustomComponent.prototype.checkSize = function (size) {
        // helper for checking maximum blob size
        if (this._parameterDefinition.max > 0) {
            return size <= this._parameterDefinition.max;
        }
        return true;
    };
    // helper for clearing selected file
    FileCustomComponent.prototype.clear = function () {
        if (this._queue.use) {
            this._queue.add(this, null);
            return;
        }
        else {
            this.parameterDefinition.update("");
        }
        this.value = null;
        if (this._fileInput.files.length > 0) {
            this._fileInput.value = null;
        }
        else {
            this.bindChangeEvent();
        }
        this.updateLabel();
    };
    FileCustomComponent.prototype.dragHandler = function (e) {
        e.stopPropagation();
        e.preventDefault();
    };
    FileCustomComponent.prototype.dropHandler = function (e) {
        this.dragHandler(e);
    };
    FileCustomComponent.prototype.log = function (msg) {
        console.warn(msg);
    };
    FileCustomComponent.prototype.removeUploadError = function () {
        this.label.innerHTML = '';
        this.label.classList.remove('uk-text-danger');
    };
    FileCustomComponent.prototype.updateLabel = function (val) {
        var _this = this;
        var names = [];
        var value = val;
        if (!value) {
            for (var i = 0; i < this.fileInput.files.length; i++) {
                names.push(this.fileInput.files.item(i).name);
            }
            this._fileName = names.join(', ');
        }
        if (value || this._fileName) {
            // show the filename, ignore file id, instead attach an icon to the label which already shows the filename
            if (this._fileName) { // there might be no filename
                if (this._fileName.length > 22) {
                    this.label.innerHTML = this._fileName.substr(0, 20) + "... \u274C";
                }
                else {
                    this.label.innerHTML = this._fileName + "  \u274C";
                }
            }
            else {
                this.label.innerHTML = 'Saved file  \u274C';
            }
            // make label clickable to remove selected file
            this.label.onclick = function () {
                _this.clear();
            };
        }
        else {
            this.label.innerHTML = 'Select file…';
            this.label.onclick = null;
        }
    };
    FileCustomComponent.prototype.uploadError = function () {
        this.label.innerHTML = 'upload failed';
        this.label.classList.add('uk-text-danger');
    };
    return FileCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.FileCustomComponent = FileCustomComponent;
