"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MATERIAL_SHADING = exports.MATERIAL_ALPHA = exports.TEXTURE_FILTERING = exports.TEXTURE_WRAPPING = exports.MATERIAL_SIDE = void 0;
var MATERIAL_SIDE;
(function (MATERIAL_SIDE) {
    MATERIAL_SIDE["DOUBLE"] = "double";
    MATERIAL_SIDE["FRONT"] = "front";
    MATERIAL_SIDE["BACK"] = "back";
})(MATERIAL_SIDE = exports.MATERIAL_SIDE || (exports.MATERIAL_SIDE = {}));
var TEXTURE_WRAPPING;
(function (TEXTURE_WRAPPING) {
    TEXTURE_WRAPPING[TEXTURE_WRAPPING["REPEAT"] = 10497] = "REPEAT";
    TEXTURE_WRAPPING[TEXTURE_WRAPPING["CLAMP_TO_EDGE"] = 33071] = "CLAMP_TO_EDGE";
    TEXTURE_WRAPPING[TEXTURE_WRAPPING["MIRRORED_REPEAT"] = 33648] = "MIRRORED_REPEAT";
})(TEXTURE_WRAPPING = exports.TEXTURE_WRAPPING || (exports.TEXTURE_WRAPPING = {}));
var TEXTURE_FILTERING;
(function (TEXTURE_FILTERING) {
    TEXTURE_FILTERING[TEXTURE_FILTERING["NONE"] = 0] = "NONE";
    TEXTURE_FILTERING[TEXTURE_FILTERING["NEAREST"] = 9728] = "NEAREST";
    TEXTURE_FILTERING[TEXTURE_FILTERING["LINEAR"] = 9729] = "LINEAR";
    TEXTURE_FILTERING[TEXTURE_FILTERING["NEAREST_MIPMAP_NEAREST"] = 9984] = "NEAREST_MIPMAP_NEAREST";
    TEXTURE_FILTERING[TEXTURE_FILTERING["LINEAR_MIPMAP_NEAREST"] = 9985] = "LINEAR_MIPMAP_NEAREST";
    TEXTURE_FILTERING[TEXTURE_FILTERING["NEAREST_MIPMAP_LINEAR"] = 9986] = "NEAREST_MIPMAP_LINEAR";
    TEXTURE_FILTERING[TEXTURE_FILTERING["LINEAR_MIPMAP_LINEAR"] = 9987] = "LINEAR_MIPMAP_LINEAR";
})(TEXTURE_FILTERING = exports.TEXTURE_FILTERING || (exports.TEXTURE_FILTERING = {}));
var MATERIAL_ALPHA;
(function (MATERIAL_ALPHA) {
    MATERIAL_ALPHA["OPAQUE"] = "opaque";
    MATERIAL_ALPHA["MASK"] = "mask";
    MATERIAL_ALPHA["BLEND"] = "blend";
})(MATERIAL_ALPHA = exports.MATERIAL_ALPHA || (exports.MATERIAL_ALPHA = {}));
var MATERIAL_SHADING;
(function (MATERIAL_SHADING) {
    MATERIAL_SHADING["FLAT"] = "flat";
    MATERIAL_SHADING["SMOOTH"] = "smooth";
})(MATERIAL_SHADING = exports.MATERIAL_SHADING || (exports.MATERIAL_SHADING = {}));
