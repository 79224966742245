
/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiSceneImplementationV2.1.js*
 *
 * ### Content
 *   * Implementation of the ShapeDiver 3D Viewer Scene API V2.1
 *
 * @module SceneApi
 * @author ShapeDiver <contact@shapediver.com>
 */

/**
* Imported messaging constant definitions
*/
var messagingConstants = require('../../../shared/constants/MessagingConstants');

/**
* Import GlobalUtils
*/
var GlobalUtils = require('../../../shared/util/GlobalUtils');

/**
* Message prototype
*/
var MessagePrototype = require('../../../shared/messages/MessagePrototype');

/**
* ApiInterfaceV2
*/
var ApiInterfaceV2 = new (require('../ApiInterfaceV2.1'))();

/**
* TWEEN
*/
var TWEEN = require('@tweenjs/tween.js');

/**
* APIResponse factory
*/
const APIResponse = require('../ApiResponse');

////////////
////////////
//
// Scene Interface
//
////////////
////////////

/**
 * ShapeDiver 3D Viewer API V2 - Scene Interface
 * @class
 * @implements {module:ApiSceneInterface~ApiSceneInterface}
 * @param {Object} api - The global api object to which this api belongs
 * @param {Object} references.sceneManager - Reference to the scene manager
 * @param {Object} references.viewportManager - Reference to the viewport manager
 * @param {Object} references.sceneGeometryManager - Reference to the scene geometry manager
 * @param {Object} references.interactionGroupManager - Reference to the interaction group manager
 */
var SceneApi = function (_api, ___refs) {

  var that = this;

  // include enums from interface definition
  this.FORMAT = ApiInterfaceV2.scene.FORMAT;
  this.EVENTTYPE = ApiInterfaceV2.scene.EVENTTYPE;
  this.INTERACTIONMODETYPE = ApiInterfaceV2.scene.INTERACTIONMODETYPE;
  this.SELECTIONMODETYPE = ApiInterfaceV2.scene.SELECTIONMODETYPE;
  this.TRANSFORMATIONTYPE = ApiInterfaceV2.scene.TRANSFORMATIONTYPE;
  this.LIVETRANSFORMATIONTYPE = ApiInterfaceV2.scene.LIVETRANSFORMATIONTYPE;

  // shortcuts to handlers
  var _sceneManager = ___refs.sceneManager;
  var _viewportManager = ___refs.viewportManager;
  var _sceneGeometryManager = ___refs.sceneGeometryManager;
  var _interactionGroupManager = ___refs.interactionGroupManager;
  var _messagingHandler = ___refs.messagingHandler;

  /** @inheritdoc */
  that.lights = _viewportManager.api.lights;

  /** @inheritdoc */
  that.camera = _viewportManager.api.camera;

  /** @inheritdoc */
  this.updateAsync = function (assets, pluginId, payload, options) {

    // parameter sanity checks
    if (!Array.isArray(assets)) {
      assets = [assets];
    }

    // use API runtime id unless we were given a plugin id
    let creatorId = pluginId || _api.getRuntimeId();

    let outputVersions = [];
    let assetIds = [];

    for (let asset of assets) {

      // check and collect asset ids
      if (!asset || !asset.id) {
        return Promise.resolve(APIResponse('Asset without id property', null, payload));
      }
      assetIds.push(asset.id);

      // copy asset object so that we don't change the original
      // exception: the canvas property is assigned
      let newJSON = GlobalUtils.deepCopy(asset, ['canvas', 'threeObject', 'transformations']);

      // get json definition of the current version of this output
      // we reuse all properties not set in the asset from the current state
      // without persistent attributes (except version)
      let oldJSON = _sceneManager.getJSONOutputVersion(asset.id, creatorId, false);
      if (oldJSON) {
        let keys = Object.keys(oldJSON);
        for (let k of keys) {
          if (!newJSON.hasOwnProperty(k) && k !== 'version') {
            newJSON[k] = oldJSON[k];
          }
        }
      }

      // create dummy version if none was specified
      if (!newJSON.hasOwnProperty('version')) {
        newJSON.version = GlobalUtils.createRandomId();
      }

      // no need to update asset in case its version didn't change
      if (!oldJSON || newJSON.version !== oldJSON.version) {
        outputVersions.push(newJSON);
      }
    }

    // we start a process and need a token
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;

    // this promise resolves when the scene update has succeeded,
    // or rejects if the scene update failed
    let updateCompletePromise = new Promise(function (resolve, reject) {

      // set the options to its default
      if(!options) options = {};
      if(!options.busy) options.busy = false;

      // send a process status message for starting a process in the ProcessStatusHandler
      let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_STATUS, { busy: options.busy, progress: 0 }, token);
      _messagingHandler.message(messagingConstants.messageTopics.PROCESS, m);

      // tell scene manager about the amount of output versions to expect
      let res = _sceneManager.initSubScene(outputVersions.length, creatorId, token);
      if (!res) {
        reject(new Error('Could not initialize subscene.'));
        return;
      }

      // set individual output versions
      let ovPromises = [];
      for (let outputVersion of outputVersions) {
        ovPromises.push(_sceneManager.addJSONOutputVersionToSubScene(outputVersion, creatorId, token));
      }

      return Promise.all(ovPromises).then(
        function () { // be aware that addJSONOutputVersionToSubScene resolves to false, if update has not been published
          // all ovPromises resolved
          // get data for published ids
          let publishedAssets = [];
          for (let id of assetIds) {
            let asset = _sceneManager.getJSONOutputVersion(id, creatorId, true);
            if (asset) {
              // add addEventListener property to asset
              asset.addEventListener = function (type, cb) {
                // sanity check
                if (!GlobalUtils.typeCheck(type, 'string') || type.length <= 0)
                  return APIResponse('Event type must be a string');
                let t = type;
                if (!type.endsWith(asset.scenePath)) {
                  t = type + '.' + asset.scenePath;
                }
                return that.addEventListener(t, cb);
              };
              // add to list
              publishedAssets.push(asset);
            }
          }
          if (publishedAssets.length == assetIds.length) {
            resolve(publishedAssets);
          }
          else {
            reject('Failed to publish all assets, unknown error.');
          }
        },
        function (err) {
          // one of ovPromises rejected
          reject(err);
        }
      );
    });

    // final promise ensuring we send an APIResponse
    return updateCompletePromise.then(
      function (data) {
        return APIResponse(null, data, payload);
      },
      function (err) {
        return Promise.resolve(APIResponse(err, null, payload));
      });
  };


  /** @inheritdoc */
  this.get = function (filterAsset, pluginId, blnScene) {
    filterAsset = filterAsset || {};
    if (typeof filterAsset !== 'object') filterAsset = {};

    // get assets for a given pluginId, or this API instance
    let creatorIds = [];
    if (GlobalUtils.typeCheck(pluginId, 'string')) {
      creatorIds = [pluginId];
    } else {
      creatorIds = [_api.getRuntimeId()];
      // Later we might change the API behavior to optionally return all assets in the scene,
      // but this requires a bigger change of the API interface definition
      //creatorIds = [_sceneManager.getCreatorIds();]
    }

    // get the JSON versions
    let jsonOutputIds = [];

    for (let creatorId of creatorIds) {
      // retrieve all relevant output ids from scene manager
      let outputIds = filterAsset.hasOwnProperty('id') ? [filterAsset.id] : _sceneManager.getOutputIds(creatorId);

      for (let oid of outputIds) {
        // get a deep copy of the JSON output version
        // The assets returned by getJSONOutputVersion describe their original state, without persistent attributes applied.
        let joid = _sceneManager.getJSONOutputVersion(oid, creatorId, blnScene);
        if (joid === null) {
          //id property should work as a filter criterion, i.e. we don't raise an error if no
          //asset with the given id is found, instead we return an empty array
          //return APIResponse('Id ' + oid + ' not found in scene.');
          return APIResponse(undefined, []);
        }
        jsonOutputIds.push(joid);
      }
    }

    // apply further filters
    let filters = Object.keys(filterAsset);
    for (let f of filters) {
      jsonOutputIds = jsonOutputIds.filter(function (jsoid) {
        return jsoid[f] === filterAsset[f];
      });
    }

    return APIResponse(null, jsonOutputIds);
  };

  /** @inheritdoc */
  this.getData = function (filter, plugin) {
    let f = GlobalUtils.deepCopy(filter);
    if (GlobalUtils.typeCheck(plugin, 'string')) f.plugin = plugin;
    let r = _sceneManager.getModelData(f);
    return APIResponse(null, r);
  };

  this.getInternalObject = function(path) {
    if (!GlobalUtils.typeCheck(path, 'string'))
      return APIResponse('A Path of type string must be provided.');
    return _sceneGeometryManager.getPathUtils().getPathObject(_sceneGeometryManager.getGeometryNode(), path);
  }

  /** @inheritdoc */
  this.convertTo2D = function (position) {
    return _viewportManager.api.convertTo2D(position);
  };

  /** @inheritdoc */
  this.getBoundingBox = function (path) {
    if (path && !GlobalUtils.typeCheck(path, 'string'))
      return APIResponse('Path must be a string.');
    let res = _sceneGeometryManager.getBoundingBox(path);
    if (!res)
      return APIResponse('No Object was found at this path.');
    return APIResponse(null, res);
  };

  /** @inheritdoc */
  this.setTransformation = function (type, id, matrix) {
    if ( typeof matrix[0] === 'number' || ('isMatrix4' in matrix && matrix.isMatrix4 === true)) {
      if (!GlobalUtils.typeCheck(matrix, 'matrix4x4arr') && !('isMatrix4' in matrix && matrix.isMatrix4 === true))
        return APIResponse('The provided matrix is not valid.');
    } else {
      for (let i = 0, len = matrix.length; i < len; i++) {
        if (matrix[i] !== null && (!GlobalUtils.typeCheck(matrix[i], 'matrix4x4arr') && !(matrix[i] && 'isMatrix4' in matrix[i] && matrix[i].isMatrix4 === true)))
          return APIResponse('At least one provided matrix is not valid.');
      }
    }

    if (type === this.TRANSFORMATIONTYPE.PLUGIN) {
      let plugins = _api.plugins.get().data;
      if (id) {
        let found = false;
        for (let i = 0, len = plugins.length; i < len; i++)
          if (plugins[i].id === id) {
            _sceneGeometryManager.setPluginTransformation(id, matrix);
            found = true;
          }

        if (!found) return APIResponse('Plugin with id ' + id + ' does not exist.');
      } else {
        for (let i = 0, len = plugins.length; i < len; i++)
          _sceneGeometryManager.setPluginTransformation(plugins[i].id, matrix);
      }
      _viewportManager.api.updateShadowMap();
      _viewportManager.api.render();
    } else if (type === this.TRANSFORMATIONTYPE.VIEWPORT) {
      _viewportManager.api.setTransformation(id, matrix);
    } else {
      return APIResponse('Invalid transformation type. Types: "plugin", "viewport"');
    }
    return APIResponse(null, true);
  };

  /** @inheritdoc */
  this.getTransformation = function (type, id) {
    if (type === this.TRANSFORMATIONTYPE.PLUGIN) {
      let plugins = _api.plugins.get().data;
      if (id) {
        for (let i = 0, len = plugins.length; i < len; i++) {
          if (plugins[i].id === id) {
            let m = _sceneGeometryManager.getPluginTransformation(id);
            let response = [];
            for (let j = 0, len2 = m.length; j < len2; j++) {
              response[j] = m[j].transpose().elements;
            }
            return APIResponse(null, response.length === 1 ? response[0] : response);
          }
        }
        return APIResponse('Plugin with id ' + id + ' does not exist.');
      } else {
        if (plugins.length == 1) {
          let m = _sceneGeometryManager.getPluginTransformation(plugins[0].id);
          let response = [];
          for (let j = 0, len2 = m.length; j < len2; j++) {
            response[j] = m[j].transpose().elements;
          }
          return APIResponse(null, response.length === 1 ? response[0] : response);
        } else {
          let data;
          for (let i = 0, len = plugins.length; i < len; i++) {
            let m = _sceneGeometryManager.getPluginTransformation(plugins[i].id);
            let response = [];
            for (let j = 0, len2 = m.length; j < len2; j++) {
              response[j] = m[j].transpose().elements;
            }
            data[plugins[i].id] = response.length === 1 ? response[0] : response;
          }
          return APIResponse(null, data);
        }
      }
    } else if (type === this.TRANSFORMATIONTYPE.VIEWPORT) {
      return APIResponse(null, _viewportManager.api.getTransformation(id));
    } else {
      return APIResponse('Invalid transformation type. Types: "plugin", "viewport"');
    }
  };

  /** @inheritdoc */
  this.applyTransformation = function (type, id, matrix) {
    if ( typeof matrix[0] === 'number' || ('isMatrix4' in matrix && matrix.isMatrix4 === true)) {
      if (!GlobalUtils.typeCheck(matrix, 'matrix4x4arr') && !('isMatrix4' in matrix && matrix.isMatrix4 === true))
        return APIResponse('The provided matrix is not valid.');
    } else {
      for (let i = 0, len = matrix.length; i < len; i++) {
        if (matrix[i] !== null && (!GlobalUtils.typeCheck(matrix[i], 'matrix4x4arr') && !(matrix[i] && 'isMatrix4' in matrix[i] && matrix[i].isMatrix4 === true)))
          return APIResponse('At least one provided matrix is not valid.');
      }
    }

    if (type === this.TRANSFORMATIONTYPE.PLUGIN) {
      let plugins = _api.plugins.get().data;
      if (id) {
        let found = false;
        for (let i = 0, len = plugins.length; i < len; i++)
          if (plugins[i].id === id) {
            _sceneGeometryManager.applyPluginTransformation(id, matrix);
            found = true;
          }

        if (!found) return APIResponse('Plugin with id ' + id + ' does not exist.');
      } else {
        for (let i = 0, len = plugins.length; i < len; i++)
          _sceneGeometryManager.applyPluginTransformation(plugins[i].id, matrix);
      }
      _viewportManager.api.updateShadowMap();
      _viewportManager.api.render();
    } else if (type === this.TRANSFORMATIONTYPE.VIEWPORT) {
      _viewportManager.api.applyTransformation(id, matrix);
    } else {
      return APIResponse('Invalid transformation type. Types: "plugin", "viewport"');
    }
    return APIResponse(null, true);
  };

  /** @inheritdoc */
  this.resetTransformation = function (type, id) {
    if (type === this.TRANSFORMATIONTYPE.PLUGIN) {
      let plugins = _api.plugins.get().data;
      if (id) {
        let found = false;
        for (let i = 0, len = plugins.length; i < len; i++)
          if (plugins[i].id === id) {
            _sceneGeometryManager.resetPluginTransformation(id);
            found = true;
          }

        if (!found) return APIResponse('Plugin with id ' + id + ' does not exist.');
      } else {
        for (let i = 0, len = plugins.length; i < len; i++)
          _sceneGeometryManager.resetPluginTransformation(plugins[i].id);
      }
      _viewportManager.api.updateShadowMap();
      _viewportManager.api.render();
    } else if (type === this.TRANSFORMATIONTYPE.VIEWPORT) {
      _viewportManager.api.resetTransformation(id);
    } else {
      return APIResponse('Invalid transformation type. Types: "plugin", "viewport"');
    }
    return APIResponse(null, true);
  };

  /** @inheritdoc */
  this.setLiveTransformation = function (liveTransformations, viewports, duration) {
    let scope = 'setLiveTransformation';

    // check the viewports
    if (viewports && !GlobalUtils.typeCheck(viewports, 'string') && !Array.isArray(viewports))
      return APIResponse('The provided viewports is not of type array or string, but also not null.');

    if (GlobalUtils.typeCheck(viewports, 'string') && !Array.isArray(viewports))
      viewports = [viewports];

    if (!viewports) {
      viewports = [];
      _viewportManager.getApis().forEach(function(element) {
        viewports.push(element.getViewportRuntimeId());
      });
    }
      
    // check the duration
    if (duration && !GlobalUtils.typeCheck(duration, 'notnegative')) {
      return APIResponse('The provided duration is not a valid number.');
    } else if (!duration) {
      duration = Infinity;
    }

    // check the live transformations
    let transformations;

    if (!Array.isArray(liveTransformations)) {
      transformations = [liveTransformations];
    } else {
      transformations = liveTransformations;
    }

    if (transformations.length === 0)
      return APIResponse('No live transformations were provided.');

    for (let i = 0, len = transformations.length; i < len; i++) {
      let transformation = transformations[i];

      // scenePaths
      if (!transformation.scenePaths)
        return APIResponse('No scene paths provided.');

      if (!Array.isArray(transformation.scenePaths))
        transformation.scenePaths = [transformation.scenePaths];

      for (let j = 0, len2 = transformation.scenePaths.length; j < len2; j++) {
        let s = transformation.scenePaths[j];
        if (!GlobalUtils.typeCheck(s, 'string'))
          return APIResponse('One of the provided scenePaths of a live transformation is not of valid type.');
      }

      // transformations
      if (!transformation.transformations)
        return APIResponse('No live transformations provided.');

      if (!Array.isArray(transformation.transformations))
        transformation.transformations = [transformations];

      if (transformation.transformations.length === 0)
        return APIResponse('One of the provided live transformations does not have any transformations.');

      for (let j = 0, len2 = transformation.transformations.length; j < len2; j++) {
        let t = transformation.transformations[j];

        // type
        if (!GlobalUtils.typeCheck(t.type, 'string') ||
          !(t.type === that.LIVETRANSFORMATIONTYPE.ROTATION || t.type === that.LIVETRANSFORMATIONTYPE.TRANSLATION || t.type === that.LIVETRANSFORMATIONTYPE.SCALING))
          return APIResponse('No valid transformation type provided for at least one of the live transformations.');

        // duration
        if (!GlobalUtils.typeCheck(t.duration, 'notnegative'))
          return APIResponse('No valid duration provided for at least one of the live transformations.');

        // [delay]
        if (!GlobalUtils.typeCheck(t.delay, 'notnegative')) {
          if (t.delay) _api.warn(scope, 'The value ' + t.delay + ' is not a valid input for the delay. It is set to the default (0).');
          t.delay = 0;
        }

        // [repeat]
        if (!GlobalUtils.typeCheck(t.repeat, 'notnegative')) {
          if (t.repeat) _api.warn(scope, 'The value ' + t.repeat + ' is not a valid input for the repeat. It is set to the default (0).');
          t.repeat = 0;
        }

        // [yoyo]
        if (!GlobalUtils.typeCheck(t.yoyo, 'boolean')) {
          if (t.yoyo) _api.warn(scope, 'The value ' + t.yoyo + ' is not a valid input for the yoyo. It is set to the default (false).');
          t.yoyo = false;
        }

        // [parent]
        if (!GlobalUtils.typeCheck(t.parent, 'string')) {
          if (t.parent) _api.warn(scope, 'The value ' + t.parent + ' is not a valid input for the parent. It is set to the default (null).');
          t.parent = null;
        } else if (!_sceneGeometryManager.getBoundingBox(t.parent)) {
          _api.warn(scope, 'The value ' + t.parent + ' is not a valid path to a parent. It is set to the default (null).');
          t.parent = null;
        }

        // [easing]
        if (!t.easing) {
          t.easing = TWEEN.Easing.Linear.None;
        } else if (GlobalUtils.typeCheck(t.easing, 'string')) {
          t.easing = GlobalUtils.getAtPath(TWEEN.Easing, t.easing);
          if (!t.easing) {
            _api.warn(scope, 'The value ' + t.easing + ' is not a valid input for the easing. It is set to the default (Linear.None).');
            t.easing = TWEEN.Easing.Linear.None;
          }
        } else if (!GlobalUtils.typeCheck(t.easing, 'function')) {
          if (!t.easing) {
            _api.warn(scope, 'The value ' + t.easing + ' is not a valid input for the easing. It is set to the default (Linear.None).');
            t.easing = TWEEN.Easing.Linear.None;
          }
        }

        // [pivot]
        if (!GlobalUtils.typeCheck(t.pivot, 'vector3any')) {
          if (t.pivot) _api.warn(scope, 'The value ' + t.pivot + ' is not a valid input for the pivot. It is set to the default (null).');
          t.pivot = null;
        }

        // [rotationAxis]
        if (!GlobalUtils.typeCheck(t.rotationAxis, 'vector3any') && t.type === that.LIVETRANSFORMATIONTYPE.ROTATION)
          return APIResponse('No valid rotationAxis provided for at least one of the live transformations with type ROTATION.');

        // [rotationDegree]
        if (!GlobalUtils.typeCheck(t.rotationDegree, 'number') && t.type === that.LIVETRANSFORMATIONTYPE.ROTATION)
          return APIResponse('No valid rotationDegree provided for at least one of the live transformations with type ROTATION.');

        // [scalingVector]
        if (!GlobalUtils.typeCheck(t.scalingVector, 'vector3any') && t.type === that.LIVETRANSFORMATIONTYPE.SCALING)
          return APIResponse('No valid scalingVector provided for at least one of the live transformations with type SCALING.');

        // [translationVector]
        if (!GlobalUtils.typeCheck(t.translationVector, 'vector3any') && t.type === that.LIVETRANSFORMATIONTYPE.TRANSLATION)
          return APIResponse('No valid translationVector provided for at least one of the live transformations with type TRANSLATION.');
      }

      // [reset]
      if (!GlobalUtils.typeCheck(transformation.reset, 'boolean')) {
        if (transformation.reset) _api.warn(scope, 'The value ' + transformation.reset + ' is not a valid input for the reset. It is set to the default (true).');
        transformation.reset = true;
      }
    }

    let data = [];
    for (let j = 0, l = viewports.length; j < l; j++) {
      let responses = [],
          stops = [],
          resets = [],
          promises = [];
      let g = _viewportManager.api.setLiveTransformation(viewports[j], transformations, duration);
      for (let i = 0, len = g.length; i < len; i++) {
        responses.push({
          stop: g[i].stop,
          reset: g[i].resetTransformation,
          promise: g[i].promise
        });
        stops.push(g[i].stop);
        resets.push(g[i].resetTransformation);
        promises.push(g[i].promise);
      }
      data.push({
        responses: responses,
        stop: function (reset) {
          for (let i = 0, len = stops.length; i < len; i++)
            stops[i](reset);
        },
        reset: function () {
          for (let i = 0, len = resets.length; i < len; i++)
            resets[i]();
        },
        promise: Promise.all(promises)
      });
    }

    return APIResponse(null, data.length === 1 ? data[0] : data);
  };

  /** @inheritdoc */
  this.getScreenshot = function () {
    return _viewportManager.api.getScreenshot();
  };

  /** @inheritdoc */
  this.getScreenshotAsync = function () {
    return _viewportManager.api.getScreenshotAsync();
  };

  /** @inheritdoc */
  this.updateSelected = function (select, deselect) {
    return _viewportManager.api.updateSelected(select, deselect);
  };

  /** @inheritdoc */
  this.getSelected = function () {
    return _viewportManager.api.getSelected();
  };

  /** @inheritdoc */
  this.startExternalDragEvent = function(path, eventType) {
    return _viewportManager.api.startExternalDragEvent(path, eventType);
  }

  this.rayTraceScene = function(origin, end) {
    return _viewportManager.api.rayTraceScene(origin, end);
  }

  /** @inheritdoc */
  this.removeAsync = function (filter, pluginId, payload) {
    // have we been given a pluginId?
    let creatorId = pluginId || _api.getRuntimeId();

    // get list of filtered assets
    let res_get = that.get(filter, creatorId, true);
    res_get.payload = payload;
    if (res_get.err) return res_get;

    let idArr = [];
    for (let asset of res_get.data) {
      idArr.push(asset.id);
    }

    return _sceneManager.removeOutputIds(creatorId, idArr).then(
      function () {
        return APIResponse(null, res_get.data, payload);
      },
      function (err) {
        return Promise.resolve(APIResponse(err, null, payload));
      }
    );

  };

  /** @inheritdoc */
  this.updatePersistentAsync = function (assets, pluginId, payload) {
    if (!Array.isArray(assets)) {
      assets = [assets];
    }

    let creatorId = pluginId || _api.getRuntimeId();

    let promises = [];
    for (let asset of assets) {
      if (asset === undefined || typeof asset !== 'object') {
        return Promise.resolve(APIResponse('Unexpected asset format', null, payload));
      }
      if (!asset.hasOwnProperty('id')) {
        return Promise.resolve(APIResponse('Asset does not have "id" attribute.', null, payload));
      }
      promises.push(_sceneManager.setPersistentAttributes(asset.id, creatorId, asset));
    }

    return Promise.all(promises).then(function (data) {
      return APIResponse(null, data, payload);
    }).catch(function (err) {
      return Promise.resolve(APIResponse(err, null, payload));
    });
  };

  /** @inheritdoc */
  this.getPersistent = function (filter, pluginId) {
    // if no plugin id has been specified, use this API
    let creatorId = pluginId || _api.getRuntimeId();
    // get assets matching filter, based on assets without persistent attributes applied
    let res = that.get(filter, creatorId, false);
    if (res.err) return res;
    // get persistent attributes for each asset
    let attr = [];
    for (let asset of res.data) {
      let a = _sceneManager.getPersistentAttributes(asset.id, creatorId);
      if (a) attr.push(a);
    }
    return APIResponse(null, attr);
  };

  /** @inheritdoc */
  this.updateInteractionGroups = function (groups) {

    // parameter sanity checks
    if (!Array.isArray(groups)) {
      groups = [groups];
    }

    for (let group of groups) {

      if (!group || !group.id) {
        return APIResponse('Interaction group without id property');
      }

      // set default values for hoverable, selectable, draggable
      let g = {};
      ['hoverable', 'selectable', 'draggable'].forEach(function (attr) {
        g[attr] = group[attr] || false;
      });
      if (group.selectionMode)
        g.selectionMode = group.selectionMode;
      g.name = group.id;

      let checkEffect = function (effect) {
        let ps = ['active', 'passive'];
        for (let p in ps) {
          let e = effect[p];
          if (e) {
            if (typeof e !== 'object') {
              return 'Effect has invalid type. Should be object.';
            }
            if (!e.name) {
              return 'Effect has no name property.';
            }
            if (['colorHighlight', 'opacityHighlight'].indexOf(e.name) == -1) {
              return 'Invalid effect type name.';
            }
          }
        }
      };

      if (g.hoverable && group.hoverEffect) {
        let msg = checkEffect(group.hoverEffect);
        if (msg) {
          return APIResponse(msg);
        } else {
          g.hoverableHighlight = group.hoverEffect;
        }
      }
      if (g.selectable && group.selectionEffect) {
        let msg = checkEffect(group.selectionEffect);
        if (msg) {
          return APIResponse(msg);
        } else {
          g.selectableHighlight = group.selectionEffect;
        }
      }
      if (g.draggable && group.dragEffect) {
        let msg = checkEffect(group.dragEffect);
        if (msg) {
          return APIResponse(msg);
        } else {
          g.draggableHighlight = group.dragEffect;
        }
      }

      if (_interactionGroupManager.isInteractionGroup(g.name)) {
        let oldGroup = _interactionGroupManager.getGroup(g.name);

        if (!g.hasOwnProperty('hoverable')) {
          g.hoverable = oldGroup.isHoverable();
        }
        if (!g.hasOwnProperty('selectable')) {
          g.selectable = oldGroup.isSelectable();
        }
        if (!g.hasOwnProperty('selectionMode')) {
          g.selectionMode = oldGroup.getSelectionMode();
        }
        if (!g.hasOwnProperty('draggable')) {
          g.draggable = oldGroup.isDraggable();
        }
        if (!g.hasOwnProperty('hoverEffect')) {
          g.hoverEffect = oldGroup.getHoverableHighlight();
        }
        if (!g.hasOwnProperty('selectionEffect')) {
          g.selectionEffect = oldGroup.getSelectableHighlight();
        }
        if (!g.hasOwnProperty('dragEffect')) {
          g.dragEffect = oldGroup.getDraggableHighlight();
        }
      }

      _interactionGroupManager.addOrReplaceInteractionGroup(g);
    }
    return APIResponse(null, true);
  };

  /** @inheritdoc */
  this.getInteractionGroups = function () {
    let groups = _interactionGroupManager.getGroups();
    let gs = [];
    for (let group of groups) {
      let g = {};
      g.id = group.getName();
      g.hoverable = group.isHoverable();
      g.selectable = group.isSelectable();
      g.selectionMode = group.getSelectionMode();
      g.draggable = group.isDraggable();
      g.hoverEffect = group.getHoverableHighlight();
      g.selectionEffect = group.getSelectableHighlight();
      g.dragEffect = group.getDraggableHighlight();

      // convert colors
      ['dragEffect', 'hoverEffect', 'selectionEffect'].forEach(function (effect) {
        if (g[effect]) {
          let e = g[effect];
          let outEffect = {};
          ['active', 'passive'].forEach(function (ap) {
            if (e[ap]) {
              outEffect[ap] = { name: e[ap].name };
              if (e[ap].options) {
                outEffect[ap].options = {};
                if (outEffect[ap].options.opacity) {
                  outEffect[ap].options.opacity = e[ap].options.opacity;
                }
                if (e[ap].options.color) {
                  let c = e[ap].options.color;
                  if (['r', 'g', 'b'].every((attr) => { return c.hasOwnProperty(attr); })) {
                    outEffect[ap].options.color = [c.r * 255, c.g * 255, c.b * 255];
                  }
                }
              }
            }
          });
          g[effect] = outEffect;
        }
      });
      gs.push(g);
    }
    return APIResponse(null, gs);
  };

  /** @inheritdoc */
  this.addEventListener = function (typeAndPath, cb) {
    // sanity check
    if (!GlobalUtils.typeCheck(typeAndPath, 'string') || typeAndPath.length <= 0)
      return APIResponse('Event type must be a string');
    // check if event type is supported
    let type;
    if (!Object.keys(that.EVENTTYPE).find((k) => {
      let ty = that.EVENTTYPE[k];
      if (typeAndPath.startsWith(ty)) {
        type = ty;
        return true;
      }
      return false;
    })) {
      return APIResponse('Unsupported event type');
    }
    // compose topic and subscribe to message stream
    let t = messagingConstants.messageTopics.SCENE + '.' + typeAndPath;
    let subtokens = _api.subscribeToMessageStream(t, function (topic, msg) {
      // create event object, add common event properties
      let event = new CustomEvent(type);
      event.api = msg.api;
      if (msg.token) event.token = msg.token;
      // get relevant data parts from message, add special event properties
      let partTypes = {};
      partTypes[messagingConstants.messageDataTypes.SCENE_INTERACTION] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.GENERIC] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.SCENE_ANCHORDATA] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.SUBSCENE_UPDATE] = { assets: 1 }; // null: copy all properties
      for (let pt in partTypes) {
        let part = msg.getUniquePartByType(pt);
        if (part) {
          if (part.data) part = part.data;
          // add special event properties
          let props = partTypes[pt] ? partTypes[pt] : part;
          for (let k in props) {
            // in case event[k] is an array, and part[k] is an array, should we merge the arrays?
            event[k] = part[k];
          }
        }
      }
      // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
      cb(event);
    });
    return APIResponse(null, subtokens);
  };

  /** @inheritdoc */
  this.removeEventListener = function (token) {
    return APIResponse(null, _api.unsubscribeFromMessageStream(token));
  };

  /** @inheritdoc */
  this.render = function () {
    return _viewportManager.api.render();
  };

  /** @inheritdoc */
  this.updateShadowMap = function () {
    return _viewportManager.api.updateShadowMap();
  };

  /** @inheritdoc */
  this.startContinuousRendering = function () {
    return _viewportManager.api.startContinuousRendering();
  };

  /** @inheritdoc */
  this.stopContinuousRendering = function () {
    return _viewportManager.api.stopContinuousRendering();
  };

  /** @inheritdoc */
  this.pause = function () {
    return _viewportManager.api.pause();
  };

  /** @inheritdoc */
  this.resume = function () {
    return _viewportManager.api.resume();
  };

  /** @inheritdoc */
  this.toggleGeometry = function (show, hide) {   
    return _viewportManager.api.toggleGeometry(show, hide);
  };

  /** @inheritdoc */
  this.getViewportRuntimeId = function () {
    return _viewportManager.api.getViewportRuntimeId();
  };

  /** @inheritdoc */
  this.getContainer = function (id) {
    return _viewportManager.api.getContainer(id);
  };
};

module.exports = SceneApi;
