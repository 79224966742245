/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2022 _ShapeDiver GmbH_
 *
 * *GlobalUtilsThreeJs.js*
 *
 * ### Content
 *   * commonly shared utility functionality
 *
 * @module GlobalUtilsThreeJs
 * @author Michael Oppitz <michael@shapediver.com>
 */

const THREE = require('../../externals/three'),
    TOTINYCOLOR = require('./toTinyColor');

////////////
////////////
//
// Commonly shared utility functionality
//
////////////
////////////

/**
* Definition of global utility functionality
* @class GlobalUtilsThreeJs
*/
var GlobalUtilsThreeJs = function () {

    /**
    * Helper for converting to a THREE.Vector3, idempotent for THREE.Vector3.
    * Use this if you are given an object which is one of the following,
    * and you want to ensure to get back a THREE.Vector3:
    *
    *  * an array of 3 numbers
    *  * an object which has number properties x,y,z
    *  * a THREE.Vector3
    *
    * CAUTION: does not do checking for validity of v, use typeCheck if you need that
    *
    * @param {Number[]|Vector3|Object} v - the value to convert to THREE.Vector3, CAUTION no validity checking of v is done!!! Use typeCheck if you need that
    * @return {Vector3}
    */
    this.toVector3 = function (v) {
        if (v.isVector3) {
            return v;
        } else if (Array.isArray(v) && v.length >= 2) {
            let vec = new THREE.Vector3();
            vec.fromArray(v);
            return vec;
        } else {
            return new THREE.Vector3(v.x, v.y, v.z);
        }
    };

    /**
     * Reference to toTinyColor
     */
    this.toTinyColor = TOTINYCOLOR;

    return this;
};

// export the constructor
module.exports = new GlobalUtilsThreeJs();
